"use Server";
import React, { createContext, useState } from "react";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import { EzzoAddress, EzzoAbi } from "./constant";
import { toast } from "react-toastify";

export const StakingApp = createContext();

export const Staking = ({ children }) => {
  const { address } = useAccount();
  const [value, setValue] = useState(1);
  const [round, setRound] = useState();
  const [currentPrice, setCurrenPrice] = useState();
  const [soldKeys, setSoldKeys] = useState();
  const [recipient, setRecipient] = useState();
  const [keyalance, setKeyBalance] = useState();
  const [refral, setreafral] = useState();
  const [farming, setFarming] = useState();
  const [tokenprekey, setTokenprekey] = useState();

  useContractRead({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "currentRound",
    // args: [address],
    onSuccess(data) {
      setRound(data.toString());
    },

    watch: true,
  });

  useContractRead({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "currentPrice",
    // args: [address],
    onSuccess(data) {
      setCurrenPrice(Number(data) / 10 ** 18);
    },

    watch: true,
  });

  useContractRead({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "soldKeys",
    // args: [address],
    onSuccess(data) {
      setSoldKeys(Number(data));
    },

    watch: true,
  });

  useContractRead({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "keys",
    args: [address],
    onSuccess(data) {
      setKeyBalance(Number(data[0]) / 10 ** 18);
      setreafral(data[4].toString());
    },

    watch: true,
  });

  useContractRead({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "FARMING_SUPPLY",
    // args: [address],
    onSuccess(data) {
      setFarming(Number(data) / 10 ** 18);
    },

    watch: true,
  });
  useContractRead({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "TOKENS_PER_KEY",
    // args: [address],
    onSuccess(data) {
      setTokenprekey(Number(data) / 10 ** 18);
    },

    watch: true,
  });

  const { write: buy, isLoading: val2 } = useContractWrite({
    address: EzzoAddress,
    abi: EzzoAbi,
    functionName: "buyKey",

    args: [
      value,
      recipient ? recipient : "0x0000000000000000000000000000000000000000",
    ],
    value: `${value * currentPrice * 10 ** 18}`,
    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`buyKey Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`buyKey is Successful ${data.hash.toString()}`);
    },
  });

  return (
    <StakingApp.Provider
      value={{
        round,
        currentPrice,
        soldKeys,
        recipient,
        setRecipient,
        value,
        setValue,
        buy,
        keyalance,
        refral,
        farming,
        tokenprekey,
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
