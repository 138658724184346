import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useContext, useEffect, useState } from "react";
import img from "./Image/logo.png";
import { useAccount } from "wagmi";
import { StakingApp } from "./Context/StakingApp";

export default function Home() {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const [isLoding, setIsLoding] = useState(true);
  const {
    round,
    currentPrice,
    soldKeys,
    recipient,
    setRecipient,
    value,
    setValue,
    buy,
    keyalance,
    refral,
    farming,
    tokenprekey,
  } = useContext(StakingApp);

  const increaseValue = () => {
    setValue((prevValue) => prevValue + 1);
  };

  const decreaseValue = () => {
    setValue((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoding(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {isLoding ? (
        <div className="page-loader">
          <div className="loader">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      ) : (
        <div
          style={{ background: "#2A1B3D" }}
          data-bs-spy="scroll"
          data-bs-target="#menunav"
        >
          <header className="navbar navbar-expand-lg py-lg-0 py-2 px-0 header">
            <nav className="container">
              <a className="navbar-brand" href="#">
                <img
                  src={img}
                  style={{ height: "50px" }}
                  className="img-fluid"
                  alt="Logo"
                  title="Ezzo"
                />
              </a>
              <div className="switch order-lg-1 ms-0 ms-lg-3">
                <div className="btn-wrapper">
                  <button
                    className="navbar-toggler theme-bg-secondary border-0 menu-toggle"
                    type="button"
                    data-label="Menu"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bars"></span>
                  </button>
                </div>

                <div className="nav-item dropdown ms-3 ms-lg-0">
                  <ul className="dropdown-menu" aria-labelledby="bd-theme-text">
                    <li>
                      <button
                        type="button"
                        className="dropdown-item d-flex align-items-center"
                        data-bs-theme-value="light"
                        aria-pressed="false"
                      >
                        <svg className="svg-sprt opacity-50 theme-icon">
                          <use href="#sun-fill"></use>
                        </svg>

                        <svg className="svg-sprt ms-auto d-none">
                          <use href="#check2"></use>
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="dropdown-item d-flex align-items-center"
                        data-bs-theme-value="dark"
                        aria-pressed="false"
                      >
                        <svg className="svg-sprt opacity-50 theme-icon">
                          <use href="#moon-stars-fill"></use>
                        </svg>

                        <svg className="svg-sprt ms-auto d-none">
                          <use href="#check2"></use>
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="dropdown-item d-flex align-items-center active"
                        data-bs-theme-value="auto"
                        aria-pressed="true"
                      >
                        <svg className="svg-sprt opacity-50 theme-icon">
                          <use href="#circle-half"></use>
                        </svg>

                        <svg className="svg-sprt ms-auto d-none">
                          <use href="#check2"></use>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul
                  className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center"
                  id="menunav"
                >
                  <li className="nav-item">
                    <a className="nav-link nav-effect active" href="#home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav-effect" href="#games">
                      Games
                    </a>
                  </li>
                  <li className="nav-item nav-effect">
                    <a className="nav-link" href="#watch">
                      Watch
                    </a>
                  </li>
                  <li className="nav-item nav-effect">
                    <a className="nav-link" href="#faq">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item nav-effect">
                    <a className="nav-link" href="https://ezzo.games/#review">
                      Review
                    </a>
                  </li>
                  {address ? (
                    <li onClick={() => open()} className="nav-item nav-effect">
                      <a className="nav-link" href="#contact">
                        Disconnect
                      </a>
                    </li>
                  ) : (
                    <li onClick={() => open()} className="nav-item nav-effect">
                      <a className="nav-link" href="#contact">
                        Contact
                      </a>
                    </li>
                  )}

                  <li className="nav-item dropdown">
                    <div className="group nav-link btn-wrap">
                      <button
                        className="btn-primary custom-btn-primary"
                        onclick="window.location.href='#footer';"
                      >
                        Mint Entry Key
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </header>

          <section className="trending py-5" id="experience">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-7">
                  <div className="border-light border rounded-3 p-4 ">
                    <h3 className="mb-3 heading">Mint Your Keys</h3>
                    <hr className="border-light" />
                    <div className="d-flex justify-content-between">
                      <span className="text-white-50 small">
                        ROUND {round} -{" "}
                        <span className="text-white">SOLD OUT</span>
                      </span>
                      <span className="small text-white-50 bg-danger rounded-5 py-1 px-2 fw-bold">
                        ROUND {round} -{" "}
                        <span className="text-white">PROCESSING</span>
                      </span>
                    </div>
                    <div className="ezzprog">
                      <div className="progress my-2">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            maxWidth: `${(soldKeys * 100) / (round * 1000)}%`,
                          }}
                        >
                          <span className="title">
                            {(soldKeys * 100) / (round * 1000)}%
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-center text-white">
                      {soldKeys} / {round * 1000} EZK (
                      {(soldKeys * 100) / (round * 1000)}%)
                    </div>

                    <div className="row align-items-center">
                      <div className="col-md-8">
                        <h3 className="mt-3">Ezzo Entry Key</h3>
                        <p className="text-white-50 mb-0">
                          The Key to asserting your eligibility for network
                          challenges and incentives.
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="qtyezzo form-group">
                          <label>Quantity</label>
                          <div
                            className="value-button"
                            id="decrease"
                            value="Decrease Value"
                            onClick={() => decreaseValue()}
                          >
                            -
                          </div>
                          <input
                            type="number"
                            id="number"
                            value={value}
                            readOnly
                          />
                          <div
                            className="value-button"
                            id="increase"
                            value="Increase Value"
                            onClick={() => increaseValue()}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="border-light" />
                    <div className="form-group border-light border-bottom mb-4">
                      <label className="text-white">1 x Ezzo Entry Key</label>
                      <div className="small text-white-50">
                        0.08 ETH per key
                      </div>
                      <div className="d-flex justify-content-between text-white mt-2">
                        <div>
                          <p className="text-white">Pay:</p>
                        </div>
                        <div>
                          <h5 className="text-white">
                            {currentPrice * value} ETH
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group my-3 ezzoinput">
                      <label className="text-white">Recipient Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={address}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group my-3 ezzoinput">
                      <label className="text-white">Referrer's Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                      />
                    </div>

                    <div className="ezzoagree">
                      <div className="form-group ">
                        <input type="checkbox" id="html" />
                        <label for="html">
                          I agree with the
                          <a href="#" className="text-warning">
                            Enter Key Agreement
                          </a>
                        </label>
                      </div>
                    </div>
                    <div className="my-3 btn-wrap d-flex justify-content-center">
                      <button
                        onClick={() => buy()}
                        className="btn-primary custom-btn-primary w-50 fw-bold text-uppercase"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="border rounded-3 p-4">
                    <h4 className="text-white">User detail</h4>
                    <hr className="border-light" />
                    <div className="d-flex justify-content-between mb-1">
                      <div>
                        <p className="text-white">Key Balance</p>
                      </div>
                      <div>
                        <p className="text-warning fw-bold">{keyalance}</p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-1">
                      <div>
                        <p className="text-white">Total Key You have</p>
                      </div>
                      <div>
                        <p className="text-white">{keyalance / tokenprekey}</p>
                      </div>
                    </div>

                    <div className="form-group my-3 ezzoinput">
                      <label className="text-white">My Referral Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={refral}
                        readOnly
                      />
                    </div>
                    <hr className="border-light" />
                    <div className="d-flex justify-content-between mb-1">
                      <div>
                        <p className="text-white">Key Incentive Rewards</p>
                      </div>
                      <div>
                        <p className="text-white">N/A</p>
                      </div>
                    </div>
                    <hr className="border-light" />
                    <div className="d-flex justify-content-between mb-1">
                      <div>
                        <p className="text-white">
                          Estimated Gas Sharing Reward
                        </p>
                      </div>
                      <div>
                        <p className="text-white">0 ETH</p>
                      </div>
                    </div>
                    <hr className="border-light" />
                    <div className="d-flex justify-content-between mb-1">
                      <div>
                        <p className="text-white">Key Farm Rewards</p>
                      </div>
                      <div>
                        <p className="text-white">{farming}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
