import "./App.css";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { zkSync, zkSyncSepoliaTestnet } from "viem/chains";
import Home from "./Home";
import { Staking } from "./Context/StakingApp";

function App() {
  const projectId = "0345c7230f52356034d20c7c5fbf2519";
  // 2. Create wagmiConfig
  const metadata = {
    name: "eezzo",
    description: "Welcome to eezzo, It is future of innovation and finance",
    url: "https://ezzo.games/",
  };

  const chains = [zkSync, zkSyncSepoliaTestnet];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });

  return (
    <WagmiConfig config={wagmiConfig}>
      <Staking>
        <Home />
        <ToastContainer />
      </Staking>
    </WagmiConfig>
  );
}

export default App;
